import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DialogConfirmComponent, DialogConfirmData } from '../components/dialog-confirm/dialog-confirm.component';
import { DialogLoadingComponent, DialogLoadingData } from '../components/dialog-loading/dialog-loading.component';

// ? is there a way to pass the fact that a prop is optional as well?
type alertDialog_opts = {
  afterClosed_returnResult?: boolean
  disableClose?: boolean
  lang: any

  // DialogConfirmData
  buttons?: DialogConfirmData["buttons"]
  icon?: DialogConfirmData["icon"]
  iconMat?: DialogConfirmData["iconMat"]
  msg: DialogConfirmData["msg"]
  noCloseBtn?: DialogConfirmData["noCloseBtn"]
}

@Injectable({
  providedIn: 'root'
})
export class ViewService {

  pipeChanged = 0

  static updateView = new Subject<any>();
  static updateView$ = ViewService.updateView.asObservable();

  dialogLoadingRef?: MatDialogRef<any>

  lang: any


  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  alertDialog(opts: alertDialog_opts): Promise<string> | void {
    const {
      afterClosed_returnResult,
      disableClose,
      lang,

      // DialogConfirmData
      buttons,
      icon,
      iconMat,
      msg,
      noCloseBtn,
    } = opts

    // * dialog setup

    let buttonsDefault: typeof buttons = [
      // {
      //   lbl: "Annulla",
      // },
      {
        // cdkFocusInitial: true,
        color: "primary",
        lbl: /* this. */lang.GLOBAL_MISC.ok,
        // lbl: "ok", // test
        matDialogClose: "confirmed",
      },
    ]
    /* if (!buttons) {
      buttonsDefault = [
        // {
        //   lbl: "Annulla",
        // },
        {
          // cdkFocusInitial: true,
          color: "primary",
          lbl: this.lang.GLOBAL_MISC.ok,
          matDialogClose: "confirmed",
        },
      ]
    } */

    // * 'data' arg

    const data: DialogConfirmData = {
      buttons: buttons ?? buttonsDefault/* ! */,
      icon: icon,
      iconMat: iconMat || {
        color: "warn",
        name: "error_outline",
      },
      msg: msg,
      noCloseBtn: noCloseBtn,
    }

    // * call

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: data,
      disableClose: disableClose,
      height: '300px',
      width: '400px',
    })

    // * dialog closure

    if (afterClosed_returnResult) {
      return new Promise((resolve) => {

        dialogRef.afterClosed().subscribe(result => {
          console.log("dialogRef.afterClosed() result", result)
          resolve(result)
          // return Promise.resolve(result)
        })

      })
    } else {
      dialogRef.afterClosed().subscribe(result => {
        console.log("dialogRef.afterClosed() result", result)
      })
      // ? unsubscribe?
    }

  }

  showLoading(msg?: string, id?: string) {
    const data: DialogLoadingData = {
      msg,
    }

    const dialogRef: MatDialogRef<any> =
      this.dialog.open(DialogLoadingComponent, {
        // this.dialogLoadingRef = this.dialog.open(DialogLoadingComponent, {
        data,
        disableClose: true,
        id,
        // panelClass: "dialog-fixed",
        // height: '300px',
        // width: '400px',
      });
    if (!id) {
      this.dialogLoadingRef = dialogRef
    }


    // let j = 0
    // while (j < 5000) {
    //   j++
    // }

    // console.log(`qui showLoading`, )
    // return null


    // dialogRef.afterClosed().subscribe((result: any) => {
    //   console.log("dialogRef.afterClosed() result", result)
    //   // switch (result) {
    //   //   case "confirmed":
    //   //   default:
    //       location.reload()
    //   // }
    // })
    // ? unsubscribe?
  }

  showLoadingAsync(msg?: string, id?: string): Promise<any> {
    return new Promise((resolve) => {
      const data: DialogLoadingData = {msg};
      const dialogRef: MatDialogRef<any> = this.dialog.open(DialogLoadingComponent, {
        data,
        disableClose: true,
        id
      });
      if (!id) {
        this.dialogLoadingRef = dialogRef;
      }
      const event: any = dialogRef.afterOpened().subscribe((respoonse) => {
        event?.unsubscribe();
        resolve(null);
      });
    });
  }

  hideLoading(dialog_id?: string) {
    if (dialog_id) {
      const dialogRef: MatDialogRef<any> | undefined = this.dialog.getDialogById(dialog_id);
      dialogRef?.close()
    } else {
      this.dialogLoadingRef?.close()
    }
  }

  hideLoadingAsync(dialog_id?: string): Promise<any> {
    return new Promise((resolve) => {
      let dialogRef: MatDialogRef<any> | undefined = this.dialogLoadingRef;
      if (dialog_id) {
        dialogRef = this.dialog.getDialogById(dialog_id);
      }
      const event: any = dialogRef?.afterClosed().subscribe(() => {
        event?.unsubscribe();
        resolve(null);
      });
      dialogRef?.close();
    });
  }

}

