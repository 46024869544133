
<div class="modal-view">

  <div mat-dialog-title
       class="mat-dialog-title">

    <button mat-icon-button
            class="close-btn"
            mat-dialog-close
    >
      <mat-icon class="mat-icon">
        close
      </mat-icon>
    </button>

    <div class="view-title">
      {{"COUNTRY_PREFERENCES.preferenze" | translate}}
    </div>

  </div>

  <div mat-dialog-content class="mat-dialog-content">

    <!-- * country/nation -->
    <div class="row-form">
      <mat-form-field *ngIf="data.selectedNation"
                      class="sbh-elem mat-form-field
            mff-select mff-select--v01"
                      appearance="fill"
      >
        <mat-label>
          {{"COUNTRY_PREFERENCES.nazione" | translate}}
        </mat-label>
        <mat-select
          [(value)]="selected.nation"
          (valueChange)="changeNation()"
        >
          <mat-option
            *ngFor="let item of data?.nations | keyvalue"
            class="mat-option"
            value="{{ item.value.code }}"
          >
            <div
              class="mat-option-inner"
              style="
                  display: flex;
                  align-items: center;
                "
            >
              <img *ngIf="item.value.image || item.value.code === '_all' else nationMobile_noImg"
                   src="{{ item.value.image || 'assets/img/world.svg' }}"
                   style="
                    margin-right: 10px;
                    border-radius: 50%;
                  "
                   height="20"
                   width="20"
              >
              <ng-template #nationMobile_noImg>
                <div
                  style="
                      margin-right: 10px;
                      height: 20px;
                      width: 20px;
                    "
                ></div>
              </ng-template>

              {{ item.value.name ||
            (item.value.nameTranslatable! | translate) }}

            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <!-- * language -->
    <div class="row-form">
      <mat-form-field *ngIf="data.selectedNation" class="sbh-elem mat-form-field mff-select mff-select--v01" appearance="fill">
        <mat-label>
          {{"COUNTRY_PREFERENCES.lingua" | translate}}
        </mat-label>
        <mat-select
          [(value)]="selected.language"
          (valueChange)="changeLanguage()"
        >
          <mat-option
            *ngFor="let item of languages | keyvalue"
            class="mat-option"
            value="{{ item.value.code }}"
          >
            <div
              class="mat-option-inner"
              style="display: flex; align-items: center;">
              {{ item.value.name }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- * currency -->
    <div class="row-form">
      <mat-form-field *ngIf="data.selectedCurrency" class="sbh-elem mat-form-field mff-select mff-select--v01" appearance="fill">
        <mat-label>
          {{"COUNTRY_PREFERENCES.valuta" | translate}}
        </mat-label>
        <mat-select
          panelClass="select-currency-panel"
          [(value)]="selected.currency"
          (valueChange)="changeCurrency()"
        >
          <mat-option
            *ngFor="let item of currencies | keyvalue"
            class="mat-option"
            value="{{ item.value.code }}"
          >
            <div class="mat-option-inner">
              {{ item.value.code }} ({{ item.value.symbol }})
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

  <div mat-dialog-actions
       class="mat-dialog-actions">

    <button mat-raised-button color="primary" (click)="close()">
      {{"COUNTRY_PREFERENCES.chiudi" | translate}}
    </button>

  </div>

</div>





