

<div class="modal-view">
  
  <div class="items-pane items-pane--loading">
    <mat-spinner 
      class="mat-spinner"
      diameter="36"
    ></mat-spinner>
    <div class="loading-text">
      {{ dataProps.msg }}
    </div>
  </div>
  
</div>

