<div class="modal-view">

  <div mat-dialog-title
       class="mat-dialog-title">

    <button mat-icon-button
            class="close-btn"
            mat-dialog-close
    >
      <mat-icon class="mat-icon">
        close
      </mat-icon>
    </button>

  </div>

  <div mat-dialog-content class="mat-dialog-content">

    <p>
      {{"COMING_SOON.messaggio" | translate}}
    </p>
  </div>

  <div mat-dialog-actions
       class="mat-dialog-actions">

    <button mat-raised-button color="primary" mat-dialog-close>
      {{"COMING_SOON.chiudi" | translate}}
    </button>

  </div>

</div>
