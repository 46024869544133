import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type DialogConfirmData = {
  noCloseBtn?: boolean
  
  // icon | iconMat | <nothing>
  icon?: string
  iconMat?: {
    color: 
      // ? for indigo+pink theme, the colors are:
      | "accent" // violet
      | "primary" // blue
      | "warn" // red
    name: string // icon's name
  }
  
  msg: string
  buttons: {
    // see html
    cdkFocusInitial?: boolean // BUG
    color?: string
    lbl: string
    matDialogClose?: string // the "returned action string"
  }[]
}

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataProps: DialogConfirmData
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

}
