import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationBarComponent implements OnInit {

  @Input() label?: string;
  @Input() path?: string;

  constructor(
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {}

  openPath(){
    if(this.path){
      this.router.navigate([this.path]);
    }else{
      this.location.back();
    }
  }

}
