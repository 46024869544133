
<div class="page-elem">
  <div class="topbar">

    <div class="logo">
      <img (click)="openPath('products')"
        class="app-logo sm"
        src="../../../assets/img/logo-only.svg"
      >
      <img (click)="openPath('products')"
        class="app-logo lg"
        src="../../../assets/img/logo-full.svg"
      >
    </div>

    <ng-container *ngIf="navService.currentPath !== 'order'">

      <div class="searchbar" [class.in_scroll]="inScroll">
        <mat-form-field
          class="mat-form-field mff-input--v01
            search-form-field"
          appearance="fill"
        >
          <mat-label>
            {{ 'TOPBAR.search' | translate }}
          </mat-label>

          <!-- searchQuery -->
          <!-- ngModel not needed 4 the autocomplete to work -->
          <input matInput type="search"
            [(ngModel)]="searchQuery"
            (ngModelChange)="searchQueryChanged()"
            [matAutocomplete]="topSearchAutocompleteId"
            [formControl]="search_formCtrl"
          >

          <button
            *ngIf="searchQuery"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchQuery=''"
          >
            <mat-icon>close</mat-icon>
          </button>

          <!-- ? search-autocomplete customization in styles.scss -->
          <!--
            [displayWith]="displayFn" -->
          <mat-autocomplete
            class="mat-autocomplete mat-autocomplete-v01
              topsearch-autocomplete"
            #topSearchAutocompleteId="matAutocomplete"
          >

            <!--
              *ngFor="let item of search_filteredItems | async
              mat-option
              [value]="item.brand"
            -->
            <!--
                | filter
                  :filterBrand_autocomplete
                  :view.pipeChanged
                  :{
                      errored: true
                  }
            -->
            <!-- (click)="searchOptionSelected(item)" -->
            <mat-option
              *ngFor="let item of search_filteredItems
                | async
                | filter:enrichBrand:view.pipeChanged:{
                    FILTER_KEY_ALL: data.FILTER_KEY_ALL,
                    nations: data.nations,
                    selected: {
                      nation: data.selectedNation?.code
                    }
                  }
                | sortAlphabetically
                  :['brand']
                  :true
                  :view.pipeChanged
                  :undefined
              "
              class="mat-option"
              [value]="searchQuery"
            >
              <div class="sao-inner">

                <div class="sao-inner-body"
                  (click)="brandClick(item)"
                >
                  <div class="sao__img">
                    <img
                      class="sao-img"
                      aria-hidden
                      src="{{ item.image }}"
                    >
                    <img *ngIf="item.$searchbarNation"
                      class="perk-nation"
                      aria-hidden
                      src="{{ item.$searchbarNation.image }}"
                    >
                  </div>

                  <div class="sao__name">
                    {{ item.brand }}
                  </div>
                </div>

                <div class="sao__end"
                  *ngIf="item.$searchbarNation"
                >

                  <!-- <button (click)="searchOptionSelected2(item)"
                    class="se-btn"
                    color="primary"
                    mat-icon-button
                  >
                    <mat-icon class="mat-icon">
                      language
                    </mat-icon>
                  </button> -->

                  <div (click)="dialogSelectNation(item)"
                    class="se-btn"
                  >
                    <mat-icon class="mat-icon" color="primary">
                      language
                    </mat-icon>
                  </div>

                </div>

              </div>
            </mat-option>

          </mat-autocomplete>

        </mat-form-field>
      </div>

      <div class="side-end">

        <div class="menu-currency" *ngIf="data.selectedNation" (click)="openSelectCountrySettings()">
          <div class="img-country">
            <img [src]="data.selectedNation.code === '_all' ? 'assets/img/world.svg' : data.selectedNation.image">
          </div>
          <div class="nation-currency">
            <p>{{data.currentLangName}}</p>
            <p *ngIf="data.selectedCurrency">
              <span class="small">{{data.selectedCurrency?.code}}</span>
              <span class="small">({{data.selectedCurrency?.symbol}})</span>
            </p>
          </div>
        </div>

        <!--<div class="currency-chip" (click)="openSelectCountrySettings()">
          <div class="cc-val cc-val&#45;&#45;code">
            {{ data.selectedCurrency?.code }}
          </div>
          -
          <div class="cc-val cc-val&#45;&#45;symbol">
            {{ data.selectedCurrency?.symbol }}
          </div>
        </div>-->

        <!--<mat-menu #currencyMenu="matMenu"
          class="mat-menu mat-menu&#45;&#45;currencies"
        >
          &lt;!&ndash; <div class="cart-menu-inner"> &ndash;&gt;
            &lt;!&ndash; * header &ndash;&gt;
            &lt;!&ndash; <div class="menu-actions"></div> &ndash;&gt;
            &lt;!&ndash; * list &ndash;&gt;
            &lt;!&ndash; <div class="menu-items"> &ndash;&gt;

              &lt;!&ndash;
                  | keyvalue
                item.value.code
                item.value.name
                item.value.symbol
              &ndash;&gt;
              <button *ngFor="let item of data.currencies
                  | sortAlphabetically
                    :['name']
                    :true
                    :view.pipeChanged
                    :undefined
                "
                mat-menu-item
                (click)="data.selectCurrency({
                  currency_code: item.code
                })"
                class="cart-menu-item"
                [ngClass]="{
                  'selected': item.code === data.selectedCurrency?.code
                }"
              >
                <div class="ci-part ci-part&#45;&#45;name">
                  {{ item.name }}
                </div>
                <div class="ci-part ci-part&#45;&#45;symbol">
                  {{ item.symbol }}
                </div>
              </button>

            &lt;!&ndash; </div> &ndash;&gt;
          &lt;!&ndash; </div> &ndash;&gt;
        </mat-menu>-->


        <!-- [matMenuTriggerFor]="cartMenu" -->
        <div (click)="openPage('cart')"
          class="kart-count"
        >
          <!-- <mat-icon class="mat-icon count-icon">
            shopping_cart
          </mat-icon> -->

          <img class="mat-icon count-icon"
            src="../../../assets/img/shopping-cart.svg">

          <!--
            *ngIf="cartIsPopulated" -->
          <!-- [ngStyle]="{
              'display: none': !cartIsPopulated
            }" -->
          <div *ngIf="cartIsPopulated"
            class="badge-count"
          >
            {{ sumCartProductQuantities_result }}
            <!-- {{ sumCartProductQuantities() }} -->
          </div>

          <!-- <div class="count">
            1
          </div> -->
        </div>

        <!-- <mat-menu #cartMenu="matMenu"
          class="mat-menu mat-menu--cart"
        >
          <div class="cart-menu-inner">
            <div class="menu-actions">



            </div>
            <div class="menu-items">

              <!~~ mat-menu-item ~~>
              <!~~ <button mat-menu-item> ~~>
                <!~~ <div class="cart-menu-item"> ~~>
                  <div
                    class="cart-menu-item__inner"
                  >

                  <!~~ src="assets/img/_nathan-fertig-0EuYG6tl01Y-unsplash.jpg" ~~>
                    <img
                      class="mi-avatar"
                      src=""
                    >

                    <div class="mi-lines">
                      <div class="line line--1">
                        <!~~ Steam EUR ~~>
                        SteamEURfewjnfewoifuewnfoenwfonwefuoweofuweoufowuhfhuewuhofeouhoeuof
                      </div>
                      <div class="line line--2">
                        €10.00
                      </div>
                    </div>

                    <button
                      mat-icon-button
                      class="mi-btn"
                      color="primary"
                    >
                      <mat-icon>
                        edit
                      </mat-icon>
                    </button>
                  </div>
                <!~~ </div> ~~>
              <!~~ </button> ~~>

              <button mat-menu-item>
                <div class="">
                  <!~~ <mat-icon>dialpad</mat-icon> ~~>
                  <span>Redialfwhifbewfiuwifewhfewhwefhuuhfewuhfwehufwehufwehu</span>
                </div>
              </button>

            </div>
          </div>
        </mat-menu> -->

      </div>
    </ng-container>

  </div>
</div>



