
<div class="modal-view">

  <div mat-dialog-title
    class="mat-dialog-title">

    <button mat-icon-button
      class="close-btn"
      mat-dialog-close
    >
      <mat-icon
        class="mat-icon"
      >
        close
      </mat-icon>
    </button>

    <div class="view-title">
      {{ 'SELECT_NATION.page_title' | translate }}
    </div>
    <!--
      [innerHTML]="'SELECT_NATION.desc' | translate" -->
    <div class="view-desc">
      {{ 'SELECT_NATION.desc' | translate }}
    </div>

    <!-- <div class="search-wrap">
      <mat-form-field
        class="mat-form-field mff-input--v01
          search-form-field"
        appearance="fill"
      >

        <mat-label>
          {{ 'GLOBAL_MISC.search' | translate }}
        </mat-label>

        <input [(ngModel)]="searchQuery"
          matInput
          type="text"
        >

        <button *ngIf="searchQuery"
          (click)="searchQuery=''"
          matSuffix
          mat-icon-button
          aria-label="Clear"
        >
          <mat-icon>close</mat-icon>
        </button>

      </mat-form-field>
    </div> -->

  </div>

  <div mat-dialog-content
    class="mat-dialog-content">

    <div class="nation-grid">

      <div *ngFor="let item of dataProps.nations
        | sortAlphabetically
          :['name']
          :true
          :view.pipeChanged
          :undefined
        "
        class="nation-pane"
        [mat-dialog-close]="{
          selectedNationCode: item.code
        }"
      >
        <img
          class="np-img"
          src="{{ item.image }}"
        >
        <div class="np-name">
          {{ item.name }}
        </div>
      </div>

    </div>

    <!-- ! height test -->
    <!-- <div style="
      height: 2000px;
    "></div> -->
  </div>

  <div mat-dialog-actions
    class="mat-dialog-actions">
    <!--
      src
      https://material.angular.io/components/dialog/overview
      1st example
    -->

    <!-- (click)="onNoClick()" -->

    <button mat-button
      mat-dialog-close
    >
      {{ 'GLOBAL_MISC.close' | translate }}
    </button>

    <!-- <button mat-button
      mat-dialog-close
    >
      {{ dataProps.buttons[0] }}
    </button>
    <button mat-button
      color="primary"
      [mat-dialog-close]="'confirmed'"
      cdkFocusInitial
    >
      {{ dataProps.buttons[1] }}
    </button> -->

    <!-- <button *ngFor="let item of dataProps.buttons"
      mat-button
      [color]="item.color"
      [mat-dialog-close]="item.matDialogClose"
    >
      {{ item.lbl }}
    </button> -->

  </div>

</div>





