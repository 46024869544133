<div class="app-component">
  <app-topbar [inScroll]="inScroll"></app-topbar>

  <div class="router-outlet-wrap-2" (scroll)="onScroll($event)">
    <div class="router-outlet-wrap">
      <router-outlet></router-outlet>
    </div>

    <app-bottombar></app-bottombar>
  </div>
</div>


