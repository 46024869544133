import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  // * routes (lazy)

  {
    path: 'cart',
    loadChildren: () => import('./views/cart/cart.module').then(m => m.CartModule)
  },

  {
    path: 'checkout',
    loadChildren: () => import('./views/checkout/checkout.module').then(m => m.CheckoutModule)
  },

  {
    path: 'dummy',
    loadChildren: () => import('./views/dummy/dummy.module').then(m => m.DummyModule) },

  {
    path: 'order/:orderNo', // "orderNo" abbreviation of "orderNumber"
    loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
  },

  {
    // path: 'product/:id',
    path: 'products/:id',
    loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule)
  },
  {
    // path: 'product/:id',
    path: 'products/:id/:nation',
    loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule)
  },
  {
    // path: 'product/:id',
    path: 'products/:id/:nation/:fromRedeem',
    loadChildren: () => import('./views/product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'brand/:id',
    loadChildren: () => import('./views/brand-details/brand-details.module').then(m => m.BrandDetailsModule)
  },


  // * redirects

  //  default route
  {
    path: '',
    redirectTo: 'products',


    // * order (testing)
    // ? src: "DOCUMENTAZIONE PROGETTO WEB" > "PAGINA STATO ORDINE"
    // ? https://docs.google.com/document/d/1fCLuCYxw1EczSOpsxLD-d0pUrsyPddKuwFLbYjMjlfo/edit#heading=h.5neojlpd69og

    // status_order === 1
    // redirectTo: 'order/1635940862DudKvTpjo',

    // status_order === 3 && order.error_on_retrive_code === false
    // redirectTo: 'order/16367147111LmjRya86',
    // redirectTo: 'order/16342867597DZG4qdrRdA',

    // status_order === 3 && order.error_on_retrive_code === true &&
    // some elems of array_code[].error === true
    // redirectTo: 'order/16342867553DZG4qdrRdB',

    // * END order


    pathMatch: 'full'
  },

  {
    // untested
    path: '**',
    redirectTo: 'products',
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        // onSameUrlNavigation: "reload",

        // disable this to verify lazy loading
        preloadingStrategy: PreloadAllModules,
      }
    )

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
