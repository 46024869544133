<div class="hr mt-t1"></div>

<div class="isle isle--cart-recap mt-t1">
  <div class="isle__title sub-section-lbl sub-section-lbl--title">
    {{ 'ORDER.order_summary' | translate }}
  </div>

  <div class="isle__card">
    <ng-container *ngIf="cart">

      <div class="recap-lines">

        <div *ngFor="let item of cart.arrayProducts"
             class="recap-line">
          <div class="rl-part rl-lbl">
            {{ item.quantity_cart }} x {{ item.name }}
          </div>
          <div class="rl-part rl-cost">
            {{ item.price_converted_with_fee_cart }}
            {{ data.currenciesMap.get(item.currency_converted)?.symbol }}
          </div>
        </div>

      </div>

      <hr class="hr">

      <div class="recap-line recap-line--total">
        <div class="rl-part rl-lbl">
          {{ 'ORDER.total' | translate }}
        </div>
        <div class="rl-part rl-cost">
          {{ cart.total_with_fee }}
          {{ data.currenciesMap.get(cart.total_currency)?.symbol }}
        </div>
      </div>
      <div class="recap-line recap-line--total" *ngIf="data.cart?.total_converted_currency">
        <div class="rl-part rl-lbl">
          {{ 'ORDER.total' | translate }}
        </div>
        <div class="rl-part rl-cost">
          {{ cart.total_converted_currency }}
          {{ data.currenciesMap.get(cart.total_converted_currency)?.symbol }}
        </div>
      </div>

    </ng-container>
  </div>

</div>
