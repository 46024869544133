
<div class="modal-view">

  <div mat-dialog-title
    class="mat-dialog-title">
    <button mat-icon-button
      class="close-btn"
      mat-dialog-close
    >
      <mat-icon
        class="mat-icon"
      >
        close
      </mat-icon>
    </button>

    <img class="mdt-icon"
      src="../../../assets/img/emoji/doubt.svg"
    >
  </div>

  <div mat-dialog-content
    class="mat-dialog-content">
    <div class="msg-text">
      Sei sicuro di voler rimuovere
      <br>
      questo prodotto dal carrello?
    </div>
    <!-- ! height test -->
    <!-- <div style="
      height: 2000px;
    "></div> -->
  </div>

  <div mat-dialog-actions
    class="mat-dialog-actions">
    <!--
      src
      https://material.angular.io/components/dialog/overview
      1st example
    -->

    <!-- (click)="onNoClick()" -->
    <button mat-button
      mat-dialog-close
    >
      Annulla
    </button>
    <button mat-button
      color="primary"
      [mat-dialog-close]="'remove-item'"
      cdkFocusInitial
    >
      Si, rimuovi
    </button>
  </div>

</div>





