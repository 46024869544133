import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Utility } from 'src/app/models/utility';
import { DataService } from 'src/app/services/data.service';
import { ViewService, ViewService as View } from 'src/app/services/view.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottombarComponent implements OnInit {

  // * dom

  // DataService = DataService

  // * listener

  listener: any = {
    view: null,
  }

  // * translation

  lang: any

  // * version

  appVersion = environment.version;
  platform: number = environment.platform;

  constructor(
    public data: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    public view: ViewService,
  ) {}

  ngOnInit(): void {
    this.listenerView();

    // * translation
    this.translate.get(
      "BOTTOMBAR",
      // "CURRENCY",
      // "MISC",
    ).subscribe((lang: any) => {
      // console.log(`lang`, lang)
      this.lang = lang
      // View.updateView.next(null)

      this.initComponent()
    })

  }

  // *

  initComponent() {
    DataService.isReady().then(() => {
      View.updateView.next(null)
    })
  }

  // * language

  // appLangChanged(langKey: string) {
  //   console.log('appLangChanged() langKey', langKey)

  //   this.data.currentLang = langKey

  //   // I set this just because it's used in app.module
  //   localStorage.setItem("appLanguage", langKey)

  //   // see also: appComponent.setLang()
  //   this.translate.use(langKey)/* .subscribe(() => {
  //     console.log(`translate.currentLang ${this.translate.currentLang}`)
  //     this.data.currentLang = this.translate.currentLang
  //     // return resolve(null)
  //   }) */

  //   // location.reload()
  // }

  /* appLangChanged(langKey?: string) {
    console.log('appLangChanged() langKey', langKey)
    if (langKey) {
      this.data.selectLanguage(langKey)
    }
  } */


  // * listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log(`View.updateView$`, )
      this.view.pipeChanged++;
      this.cd.detectChanges();
      this.cd.markForCheck();
      setTimeout(() => {
        this.view.pipeChanged++;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }, 250);
    });
  }

  unsubscribeAllListeners() {
    for (const key in this.listener) {
      this.listener[key].unsubscribe()
    }
  }


  async changeLanguage(lang_code: string){
    console.log('change', lang_code, this.data.currentLang)
    if(lang_code !== this.data.currentLang){
      await this.data.selectLanguage({
        langKey: lang_code
      });
    }
  }

}
