import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { ViewService, ViewService as View } from 'src/app/services/view.service';

@Component({
  selector: 'app-lazy-img',
  templateUrl: './lazy-img.component.html',
  styleUrls: ['./lazy-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyImgComponent implements OnInit {
  
  @Input() cssClasses?: string
  @Input() src?: string
  
  lazySrc?: string
  
  initialSrc = "assets/img/transparent328x412.png"
  
  @ViewChild('imgElem') img?: ElementRef

  constructor(
    private view: ViewService,
  ) {}

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    // console.log(`this.img`, this.img/* ?.nativeElement.value */)
    this.lazyLoadImage()
  }
  
  // * 
  
  /**
   * https://stackoverflow.com/questions/32693061/how-can-i-select-an-element-in-a-component-template
   */
  lazyLoadImage() {
    View.updateView.next(null)
    // const targets = document.querySelectorAll('.gi-img');
    // const targets = this.img
    const targets = this.img?.nativeElement
    // console.log(`targets`, targets)
    // const targets = this.img?.nativeElement.value

    const lazyLoad = (target: any) => {
      const io = new IntersectionObserver((entries, observer) => {
        // console.log(`entries`, entries)
        entries.forEach(entry => {
          // console.log(`entry`, entry)

          if (entry.isIntersecting) {
            // console.log(`entry.isIntersecting`, )
            const img = entry.target;
            // const src = img.getAttribute('data-lazy');

            img.setAttribute('src', this.src || this.initialSrc);
            // img.classList.add('src-shown');
            // console.log(`qui`, )
            
            // this.lazySrc = this.src // ? doesn't work
            View.updateView.next(null)

            observer.disconnect();
          }
        });
      });

      io.observe(target)
    };

    if (targets) {
      // targets.forEach(lazyLoad);
      lazyLoad(targets)
    } else {
      // console.log(`no targets`, targets)
    }
  }
  
  
  /**
   * https://stackoverflow.com/questions/280049/how-to-create-a-javascript-callback-for-knowing-when-an-image-is-loaded
   * @param ev 
   */
  imgFinishedLoading(ev: any) {
    // console.log(`ev`, ev)
    
    // setTimeout(() => {
      // this.img?.nativeElement.setAttribute('height', null)
      // this.img?.nativeElement.setAttribute('width', null)
      this.img?.nativeElement.classList.add('src-shown')
      // this.img?.nativeElement.parentElement.classList.add('src-shown')
      // img.classList.add('src-shown');
      
      View.updateView.next(null)
    // }, 250);
  }
  imgFinishedLoadingwithError(ev: any) {
    // console.log(`imgFinishedLoadingwithError ev`, ev)
    
    this.img?.nativeElement.setAttribute('src', this.initialSrc)
    this.img?.nativeElement.classList.add('src-shown')
    // this.img?.nativeElement.style.add("display", "none") // ? dont know if this line even works
    // this.img?.nativeElement.parentElement.classList.add('src-shown')
    // img.classList.add('src-shown');
    
    View.updateView.next(null)
  }
  
  

}
