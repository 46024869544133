import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {DataService} from "../../services/data.service";
import {Currency} from "../../models/currency";
import {MatDialogRef} from "@angular/material/dialog";
import {ViewService} from "../../services/view.service";

@Component({
  selector: 'app-country-settings',
  templateUrl: './country-settings.component.html',
  styleUrls: ['./country-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySettingsComponent implements OnInit {

  languages: Map<string, {code: string, name: string}> = new Map();
  currencies: Map<string, Currency> = new Map();

  selected: any = {
    nation: this.data.selectedNation?.code,
    language: this.data.currentLang,
    currency: this.data.selectedCurrency?.code
  };

  constructor(
    public data: DataService,
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.languages;
    for(let lang in this.data.languages){
      let name: string = this.data.languages[lang];
      name = name.charAt(0).toUpperCase() + name.slice(1);
      this.languages.set(lang, {
        name: name,
        code: lang
      });
      if(lang === this.data.currentLang){
        this.selected.language = lang;
      }
    }

    for(let currency of this.data.currencies){
      this.currencies.set(currency.code, currency);
    }
  }

  // async savePreferences(){
  //   let needEvent: boolean = false;
  //   if(this.selected.currency !== this.data.selectedCurrency?.code){
  //     await this.data.selectCurrency({
  //       currency_code: this.selected.currency,
  //       noApi: true,
  //     });
  //     needEvent = true;
  //   }
  //   if(this.selected.language !== this.data.currentLang){
  //     await this.data.selectLanguage({
  //       langKey: this.selected.language,
  //       noApi: true,
  //     });
  //   }
  //   if(this.selected.selectedNation !== this.data.selectedNation?.code){
  //     await this.data.selectNation(this.selected.nation, true);
  //     needEvent = true;
  //   }
  //   await this.data.getUser({
  //     currency: this.data.selectedCurrency?.code,
  //     nation: this.data.selectedNation?.code,
  //     language: this.data.currentLang
  //   });
  //   if(needEvent){
  //     DataService.globalSettings.next({
  //       action: "all-country-settings",
  //     });
  //   }
  //   ViewService.updateView.next(null);
  //   this.dialogRef.close();
  // }

  async changeNation(){
    if(this.selected.selectedNation !== this.data.selectedNation?.code){
      await this.data.selectNation(this.selected.nation);
    }
  }

  async changeLanguage(){
    if(this.selected.selectedNation !== this.data.selectedNation?.code){
      await this.data.selectLanguage({
        langKey: this.selected.language
      });
    }
  }

  async changeCurrency(){
    if(this.selected.currency !== this.data.selectedCurrency?.code){
      await this.data.selectCurrency({
        currency_code: this.selected.currency
      });
    }
  }

  close(){
    this.dialogRef.close();
  }
}
