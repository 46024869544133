import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// export type DialogConfirmData = {
//   icon: string
//   msg: string
//   // buttons: string[]
// }

// ! UNUSED

@Component({
  selector: 'app-dialog-confirm-remove-cart-item',
  templateUrl: './dialog-confirm-remove-cart-item.component.html',
  styleUrls: ['./dialog-confirm-remove-cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogConfirmRemoveCartItemComponent implements OnInit {

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

}
