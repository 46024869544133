import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // can also be called without 'params', eg. filter:filterUser:view.pipeChanged
  transform(items: any[], func: any, changed: number, params: any): any {
    
    return (items || []).filter(item => {
      return func(item, params);
    })
    
  }

}
