import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  
  // simplified string in case of a route like
  // /routeName/:id
  currentPath?: string | null
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}
  
  // * 
  
  openPath(path: string) {
    // let navigationExtras: NavigationExtras;
    
    // navigationExtras = {
    //   queryParams: {
    //     id: product_id
    //   }
    // }
    
    this.router.navigate(
      [path], 
      // navigationExtras,
    )
  }
  
  openProductDetail(brand__id: string) {
    console.log(`openProductDetail() brand__id`, brand__id)
    
    // let navigationExtras: NavigationExtras;
    
    // navigationExtras = {
    //   queryParams: {
    //     id: product_id
    //   }
    // }
    
    
    // BUG
    // this.router.navigate(
    //   [`products/${brand__id}`], 
    //   // navigationExtras,
    // )
    // this.router.navigateByUrl(`products/${brand__id}`)
    // this.router.navigate([`products`, `${brand__id}`])
    
    
    // * works
    // src: https://stackoverflow.com/questions/40983055/how-to-reload-the-current-route-with-the-angular-2-router
    // searched: angular reload current route
    
    this.router.navigateByUrl('/dummy', {
      skipLocationChange: true,
    })
    .then(() => {
      console.log(`then`, )
      this.router.navigate([`products/${brand__id}`])
    })
    
    
  }
  
  
  
}
