import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Nation } from 'src/app/models/nation';
import { ViewService } from 'src/app/services/view.service';

export type SelectNationData = {
  brandName: string
  nations: Nation[]
}

@Component({
  selector: 'app-select-nation',
  templateUrl: './select-nation.component.html',
  styleUrls: ['./select-nation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectNationComponent implements OnInit {

  searchQuery: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataProps: SelectNationData,
    public view: ViewService,
  ) {}

  ngOnInit(): void {}

}
