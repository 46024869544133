
<div class="modal-view">
  
  <div mat-dialog-title
    class="mat-dialog-title">
    <button *ngIf="!dataProps.noCloseBtn"
      mat-icon-button
      class="close-btn"
      mat-dialog-close
    >
      <mat-icon 
        class="mat-icon"
      >
        close
      </mat-icon>
    </button>
  
    <img *ngIf="dataProps.icon else matIcon"
      class="mdt-icon"
      src="{{ dataProps.icon }}"
    >
    <ng-template #matIcon>
      <mat-icon 
        class="mdt-icon-mat"
        [color]="dataProps.iconMat?.color"
      >
        {{ dataProps.iconMat?.name }}
      </mat-icon>
    </ng-template>
  </div>
  
  <div mat-dialog-content
    class="mat-dialog-content">
    <div class="msg-text">
      <!-- Sei sicuro di voler rimuovere
      <br>
      questo prodotto dal carrello? -->
      {{ dataProps.msg }}
    </div>
    <!-- ! height test -->
    <!-- <div style="
      height: 2000px;
    "></div> -->
  </div>
  
  <div mat-dialog-actions
    class="mat-dialog-actions">
    <!--
      src
      https://material.angular.io/components/dialog/overview
      1st example
    -->
  
    <!-- (click)="onNoClick()" -->
    
    
    <!-- <button mat-button
      mat-dialog-close
    >
      {{ dataProps.buttons[0] }}
    </button>
    <button mat-button
      color="primary"
      [mat-dialog-close]="'confirmed'"
      cdkFocusInitial
    >
      {{ dataProps.buttons[1] }}
    </button> -->
    <button *ngFor="let item of dataProps.buttons"
      mat-button
      [color]="item.color"
      [mat-dialog-close]="item.matDialogClose"
    >
      {{ item.lbl }}
    </button>
  </div>
  
</div>





