import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type DialogLoadingData = {
  // // icon | iconMat | <nothing>
  // icon?: string
  // iconMat?: {
  //   color: 
  //     // ? for indigo+pink theme, the colors are:
  //     | "accent" // violet
  //     | "primary" // blue
  //     | "warn" // red
  //   name: string // icon's name
  // }
  
  // msg: string
  // buttons: {
  //   // see html
  //   cdkFocusInitial?: boolean // BUG
  //   color?: string
  //   lbl: string
  //   matDialogClose?: string // the "returned action string"
  // }[]
  msg?: string
}

@Component({
  selector: 'app-dialog-loading',
  templateUrl: './dialog-loading.component.html',
  styleUrls: ['./dialog-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogLoadingComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataProps: DialogLoadingData
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

}
