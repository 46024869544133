import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getListItem } from 'src/app/misc/util';
import { Brand } from 'src/app/models/brand';
import { Nation } from 'src/app/models/nation';
import { Product } from 'src/app/models/product';
import { DataService, TglobalSettings_subject } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ViewService, ViewService as View } from 'src/app/services/view.service';
import { SelectNationComponent, SelectNationData } from '../select-nation/select-nation.component';
import {
  BrandDetailsComponent
} from "../../views/brand-details/brand-details.component";
import {
  CountrySettingsComponent
} from "../country-settings/country-settings.component";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements OnInit {

  @Input() inScroll?: boolean;

  // * cart

  cartIsPopulated?: boolean // used in sumCartProductQuantities()
  sumCartProductQuantities_result?: string

  // * dom

  DataService = DataService

  // * listener

  listener: any = {
    view: null,
  }

  // * search

  searchQuery?: string

  search_formCtrl = new FormControl()

  // search_filteredItems?: Observable<Brand[]>
  // ? w/out this line, async pipe gives error
  // ? when sort pipe is also there
  search_filteredItems?: Observable<any>

  // * translation

  lang: any


  constructor(
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public data: DataService,
    public navService: NavigationService,
    public view: ViewService,
  ) {
    this.searchAutocompleteInit()
  }

  ngOnInit(): void {
    this.listener_globalSettings()
    this.listenerView()

    // * translation
    this.translate.get([
      // "CURRENCY",
      "GLOBAL_MISC",
      "TOPBAR",
    ]).subscribe(async (lang: any) => {
      // console.log(`lang`, lang)
      this.lang = lang
      // View.updateView.next(null)

      await DataService.isReady()
      this.initComponent()
    })

  }

  ngOnDestroy() {
    this.unsubscribeAllListeners()
  }

  initComponent() {
    // ? w/out this line when focusing the search input for the 1st time
    // ? (all unfiltered) elems don't show up
    this.searchQuery = ""
    View.updateView.next(null)
  }

  openPage(page: string) {
    switch (page) {
      case "cart":
        // let navigationExtras: NavigationExtras;

        // navigationExtras = {
        //   queryParams: {
        //     id: product_id
        //   }
        // }
        break;

      default:
        break;
    }

    this.router.navigate(
      // [`products/${product_id}`],
      [page],
      // navigationExtras,
    );
  }

  sumCartProductQuantities() {
    // console.log(`sumCartProductQuantities()`, )
    let sum = 0

    // console.log(`cart products`, this.data.cart?.arrayProducts)

    // ? data.cart.arrayProducts gets populated too late
    // this.data.cart?.arrayProducts.forEach((el: Product) => {
    this.data.currentUser?.lastCart?.arrayProducts.forEach((el: Product) => {
      if (el.quantity_cart) {
        sum += el.quantity_cart
      }
    })
    // console.log(`sum`, sum)

    let returnedValue

    if (sum > 0) {
      this.cartIsPopulated = true
      returnedValue = sum + ""
    } else {
      this.cartIsPopulated = false
    }

    if (sum > 99) {
      returnedValue += "+"
    }

    View.updateView.next(null)
    return returnedValue
  }

  // * brand

  /**
   * ? copied from products.component.ts
   */
  async brandClick(item: Brand) {
    if (!this.data.selectedNation) {
      console.log(`return: !this.data.selectedNation`, )
      return
    }

    // if (this.data.selectedNation.code === this.data.FILTER_KEY_ALL) {
    if (item.$searchbarNation?.code === this.data.selectedNation.code) {
      // brand gets "selected" in the detail page
      this.navService.openProductDetail(item._id)
    } else {
      this.dialogSelectNation(item)
    }
  }

  // * dialog

  openSelectCountrySettings(){
    const dialogRef: MatDialogRef<any> =
      this.dialog.open(CountrySettingsComponent, {
        // data: data,
        // disableClose: true,
        // id: 'my-dialog',
        // panelClass: "dialog-fixed",
        // height: '300px',
        width: '400px',
      })
  }

  /**
   * ? copied from products.component.ts
   */
  dialogSelectNation(brand: Brand) {
    // * modal setup

    let nations: Nation[] = []
    // to avoid duplicates
    let nationsObj: any = {
      // sk: "sk" // test
    }

    // ** accumulate nations
    // console.log(`Object.keys(brand.countries)`, Object.keys(brand.countries))

    Object.keys(brand.countries).map((key: string) => {
      // nations.push(
      //   /* return  */getListItem({
      //     key: key,
      //     list: this.data.nations,
      //   })
      // )
      nationsObj[key] = getListItem({
        key: key,
        list: this.data.nations,
      })
    })

    // console.log(`1st accumulation`, JSON.parse(JSON.stringify(nationsObj)))

    // ** accumulate nations thx to nation groups
    const countries_groupKeys: string[] = Object.keys(brand.countries_group)
    if (countries_groupKeys.length > 0) {

      this.data.nations.forEach((item: Nation) => {
        if (item.nation_group) {

          for (let i = 0; i < countries_groupKeys.length; i++) {
            if (countries_groupKeys[i] === item.nation_group[countries_groupKeys[i]]) {
              // nations.push(item)
              nationsObj[item.code] = item
              // test
              // if (item.code === "sk") {
              //   console.log(`sk!`, )
              // }
              break
            }
          }
          // countries_groupKeys.forEach((key: string) => {})

        }
      })

    }

    nations = Object.values(nationsObj)
    // console.log(`nations`, nations)

    // * modal call

    const data: SelectNationData = {
      brandName: brand.brand,
      nations: nations,
    }

    const dialogRef: MatDialogRef<any> =
      this.dialog.open(SelectNationComponent, {
    // this.dialogLoadingRef = this.dialog.open(DialogLoadingComponent, {
      data: data,
      // disableClose: true,
      // id,
      panelClass: "dialog-fixed",
      // height: '300px',
      // width: '400px',
    })
    // ViewService.updateView.next(null)


    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log("dialogRef.afterClosed() result", result)
      if (result?.selectedNationCode) {
        /*
        FIXME this is needed as product detail page uses
        data.selectedNation in getProducts() method

        ? see also: "DOCUMENTAZIONE PROGETTO WEB" > "TOPBAR"
        ? https://docs.google.com/document/d/1fCLuCYxw1EczSOpsxLD-d0pUrsyPddKuwFLbYjMjlfo/edit#heading=h.p0x1d3oj9zvn
        ? (there should be a note about this)
        */
        // this.selectCountry(result.selectedNationCode)
        await this.data.selectNation(result.selectedNationCode)

        // either one
        this.brandClick(brand)
        // this.openProductDetail(brand._id)
      }
    })
    // ? unsubscribe?
  }

  // * listener

  listener_globalSettings() {
    // subject type does its job even w/out importing TglobalSettings_subject
    this.listener["globalSettings"] = DataService.globalSettings$.subscribe((obj: TglobalSettings_subject) => {
      // console.log(`listener_globalSettings() obj`, obj)

      switch (obj.action) {
        case "cart_products-changed":
          this.sumCartProductQuantities_result = this.sumCartProductQuantities()
          View.updateView.next(null)
          break

        default:

      }

    })
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log(`View.updateView$`, )
      this.view.pipeChanged++;
      this.cd.detectChanges();
      this.cd.markForCheck();
      setTimeout(() => {
        this.view.pipeChanged++;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }, 250);
    });
  }

  unsubscribeAllListeners() {
    for (const key in this.listener) {
      this.listener[key].unsubscribe()
    }
  }

  // * navigation

  openPath(path: string) {
    // let navigationExtras: NavigationExtras;

    // navigationExtras = {
    //   queryParams: {
    //     id: product_id
    //   }
    // }

    this.router.navigate(
      [path],
      // navigationExtras,
    )
  }

  // * pipe

  /**
   * ? initially copied from products.component.ts (filterProduct()), then edited
   * filter list items so they're not shown in their list
   */
  enrichBrand(item: Brand, params: any) {
    // console.log('filterProduct() ', )
    const {
      // categories,
      FILTER_KEY_ALL,
      nations,
      selected,
    } = params

    if (!selected.nation) {
      selected.nation = FILTER_KEY_ALL
    }

    // * nation

    const nation: Nation = getListItem({
      key: selected.nation,
      list: nations,
    })
    // console.log(`nation`, nation)

    if (selected.nation === FILTER_KEY_ALL) {
      item.$searchbarNation = null
      // untested 'nation' should also be null in this case

    } else if (item.countries?.[selected.nation]) {
      item.$searchbarNation = nation
    } else if (item.countries_group) {
      let presentIn_countries_group = false

      for (const prop in item.countries_group) {
        // console.log(`prop`, prop)
        // console.log(`item.countries_group[prop]`, item.countries_group[prop])
        if (
          prop === nation?.nation_group?.[prop]
          // && prop !== "ww" // ? no worldwide
        ) {
          // console.log(`loop ended`, prop)
          presentIn_countries_group = true
          break
        }
      }

      if (presentIn_countries_group) {
        item.$searchbarNation = nation
      } else {
        item.$searchbarNation = null
      }
    }

    // * return

    return item
  }

  // * search

  searchQueryChanged() {
    console.log(`searchQueryChanged() `, this.searchQuery)
  }

  searchOptionSelected(item: Brand) {
    console.log(`searchOptionSelected() `, item)
    // this.searchQuery = item.brand

    // works
    // this.navService.openPath(`products/${item._id}`)
  }
  searchOptionSelected2(item: Brand) {
    console.log(`searchOptionSelected2() `, item)
  }

  // * search/autocomplete

  private _filterSearchOptions(value: string): Brand[] {
    const filterValue = value.toLowerCase();

    return (
      this.data.brands.filter(
        (item: Brand) => item.brand.toLowerCase().includes(filterValue)
      )
    )
  }

  searchAutocompleteInit() {
    this.search_filteredItems =
      this.search_formCtrl.valueChanges
        .pipe(
          startWith(''),
          map((item: string) => item ?
            this._filterSearchOptions(item) : this.data.brands.slice()
          )
        );
  }



}
