export const environment = {

  production: false,

  // apiUrl: "http://localhost:8080/webApp/",
  apiUrl: "https://api-dev.kisard.com/webApp/",
  version: "1.2",
  platform: 2, // Kisard
  appName: 'Kisard',
  g_analytics: {
    key: 'G-WQFYP6JPFV',
    event_pageLoad: 'kisard_shop_pageLoad',
    event_purchase: 'kisard_shop_purchase'
  }
};
