import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { getListItem } from '../misc/util';
import { Brand } from '../models/brand';
import { Order } from '../models/order';
import { environment } from '../../environments/environment';



// * method types
// • single source of truth when these params are passed from somewhere else
// questi tipi mi danno la possibilità di avere una singola fonte di verità
// per quanto riguarda i parametri dei metodi in ApiService

// export/*  type */ {
//   getProduct_opts as getProduct_apiOpts
// }

// * brand

type getBrandWithTranslation_opts = {
  brand__id: string
  language_code: string
}

// * cart

type postCart_opts = {
  nation_code: string
  product__id: string
  quantity: number
  user__id: string
}

// * order

type getOrder_opts = {
  order_number: Order["order_number"]
}

type postOrder_opts = {
  cart_number: string
  email: string
  user__id: string,
  platform: number,
  cancel_url?: string,
  success_base_url?: string
}

// * product

type getProduct_opts = {
  brand_id: string
  currency_code: string
  getQuantity?: boolean
  nation_code: string
}

type getProduct_quantity_opts = {
  product_id: string
}
type getProduct_quantity_response = {
  quantity: number
}



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // baseUrl = "https://api-gift-card.495318.net//webApp/"
  baseUrl = environment.apiUrl


  constructor(
    private http: HttpClient,
  ) {}


  // * brand

  getBrands() {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {};

        const url = `${this.baseUrl}brand/`

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getBrandWithTranslation(opts: getBrandWithTranslation_opts): Promise<Brand> {
    const {
      brand__id,
      language_code,
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        let url = this.baseUrl + "brand"

        if (brand__id) {
          url += "/" + brand__id
        }
        if (language_code) {
          url += "?language=" + language_code
        }

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    })
  }

  // * cart

  postCart(opts/* ? */: postCart_opts): Promise<User> {
    const {
      nation_code,
      product__id,
      quantity,
      user__id,
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        // let url = this.baseUrl + "user"

        const body = {
          idProduct: product__id,
          idUser: user__id,
          nation: nation_code,
          quantity,
        }

        this.http.post(
          this.baseUrl + "cart"
          , body
          , options
        ).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  // * general_parameter

  get_general_parameter(platform: number) {
    return new Promise(async (resolve, reject) => {
      try{
        let options = {};
        const url = `${this.baseUrl}general_parameter/${platform}`;
        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  // * order

  getOrder(opts: getOrder_opts) {
    const {
      order_number
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        let url = this.baseUrl + "order"

        if (order_number) {
          url += `/${order_number}`
        }

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postOrder(opts: postOrder_opts) {
    const {
      cart_number, // cart.cart_number
      email,
      user__id,
      cancel_url,
      success_base_url,
      platform
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {};
        const body = {
          cart_number,
          email,
          idUser: user__id,
          cancel_url,
          success_base_url,
          platform: platform
        }
        this.http.post(this.baseUrl + "coinbaseOrder", body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  postOpennodeOrder(opts: postOrder_opts) {
    const {
      cart_number, // cart.cart_number
      email,
      user__id,
      success_base_url,
      platform
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {};
        const body = {
          cart_number,
          email,
          idUser: user__id,
          success_base_url,
          platform: platform
        }
        this.http.post(this.baseUrl + "openNodeOrder", body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  /** Fa la POST di un ordine di stripe **/
  postStripeOrder(opts: postOrder_opts) {
    const {
      cart_number, // cart.cart_number
      email,
      user__id,
      cancel_url,
      success_base_url,
      platform
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {};

        const body = {
          cart_number,
          email,
          idUser: user__id,
          cancel_url: cancel_url,
          success_base_url: success_base_url,
          platform: platform
        }

        this.http.post(this.baseUrl + "stripeOrder", body, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getProducts(opts?: getProduct_opts) {
    const {
      brand_id,
      currency_code,
      getQuantity,
      nation_code,
    } = opts || {}

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        let url = this.baseUrl + "product"

        if (brand_id) {
          url += "?idBrand=" + brand_id
        }
        if (currency_code) {
          url += "&currency=" + currency_code
        }
        if (getQuantity) {
          url += "&getQuantity=" + getQuantity
        }
        if (nation_code) {
          url += "&nation=" + nation_code
        }

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }

  getProduct_quantity(
    opts/* ? */: getProduct_quantity_opts
  ): Promise<getProduct_quantity_response> {
    const {
      product_id,
    } = opts/*  || {} */

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        let url = this.baseUrl + "product/quantity"

        if (product_id) {
          // url += "?idProduct=" + product_id
          url += "/" + product_id
        }

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }


  getUser(platform: number, user__id?: string, opts?: any): Promise<User> {
    const {
      currency,
      language,
      nation
    } = opts || {}

    return new Promise(async (resolve, reject) => {
      try{
        let options = {
          // headers: <HttpHeaders> (await this.getFirebaseHeader())
        };

        let url = this.baseUrl + "user/?platform=" + platform

        if (user__id) {
          url += "&idUser=" + user__id
        }
        if (currency) {
          url += "&currency=" + currency
        }
        if (language) {
          url += "&language=" + language
        }
        if (nation) {
          url += "&nation=" + nation
        }

        this.http.get(url, options).subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          reject(err);
        });
      }catch(err){
        reject(err);
      }
    });
  }


}
