import { ViewService, ViewService as View } from "../services/view.service"
import { Nation } from "./nation"

type Translations = {
  // language code - same ones I get from general_parameter
  [key: string]: Translations_value
}
type Translations_value = {
  // html string
  description: string
  tabs: Translations_value_Tabs
}
type Translations_value_Tabs = {
  [key: number]: {
    title: string
    // html string
    description: string
  }
}


export class Brand {
  _id!: string
  brand!: string
  category: string[] = []

  // ? make into a Map?
  // countries: {
  //   [key: string]: string
  // } = {}
  // countries_group: {
  //   [key: string]: string
  // } = {}
  countries: any = {}
  countries_group: any = {}

  image?: string
  isAvaible?: boolean

  // used in topbar.component.ts > searchbar
  $searchbarNation?: Nation | null

  // * prop/translations

  private _translations?: Translations

  $translations_index0Key?: string
  $translations_index0Value?: Translations_value

  // $translations_index0Value_tabs?: Translations_value_Tabs

  // * END prop/translations

  meta!: any[];


  constructor(data: any) {
    this.create(data)
  }


  // * {pinned} method/constructor

  create(data: any) {
    if (data) {
      this._id = data._id
      this.brand = data.brand
      this.category = data.category
      this.countries = data.countries
      this.countries_group = data.countries_group
      this.image = data.image
      this.isAvaible = data.isAvaible
      this.translations = data.translations
    }
  }

  /**
   * assigns only the fields present in data
   * missing checks:
   * data may have fields which are not present in 'this'
   * data may be an entirely different class
   *
   */
  update(data: any) {
    if (data) {
      Object.keys(data).forEach((key) => {
        switch (key) {

          default:
            (<any>this)[key] = data[key]
        }

      })
    }
  }


  // * accessor

  // * accessor/translations

  public get translations(): Translations | undefined {
    return this._translations
  }
  public set translations(value: Translations | undefined) {
    this._translations = value

    // * subfield
    if (!value) {
      value = {}
      // return
    }

    this.$translations_index0Key = Object.keys(value)[0]
    this.$translations_index0Value = Object.values(value)[0]

    // test
    // #region

    /* const testVal = Object.values(value)[0]
    testVal.tabs = {
      6: {
        title: "title 6",
        description: "desc 6",
      },
      1: {
        title: "title 1",
        description: "desc 1",
      },
      3: {
        title: "title 3",
        description: "desc 3",
      },
      0: {
        title: "title 0",
        description: "desc 0",
      },
    }
    this.$translations_index0Value = testVal */

    // #endregion

    View.updateView.next(null)
  }

  // * END accessor/translations



}
