
export {
  getListItem,
}

/**
 * given a key, get a single element from a list(in any form) of items;
 * returned value is a reference, not a copy
 */
function getListItem/* <T> */(params: {
  key: string,
  list: 
    | any[] 
    | Map<string, any>
    // | any // object literal
  value?: unknown,
}) {
  const { 
    key,
    list,
    value,
  } = params
  
  if (list instanceof Array) {
    if (value) {
      const foundIndex: number = list.findIndex((el: any) => (
        // array of objects
        el[key] === value || 
        // array of primitives
        el === value
      ));
      if (foundIndex !== -1) {
        return list[foundIndex]
      }
    }
    return null
    
  } else if (list instanceof Map) {
    // console.log(`Map list`, list)
    // console.log(`Map key`, key)
    
    // ? missing check !key, still needed?
    // key not found? returns undefined (by default), changed into null
    return list.get(key) || null
  } 
  // else/*  if (list instanceof Object) */ {
  //   // * object literal
  //   console.log(`literal`, list[key])
  //   return list[key]
  //   // console.log(`instanceof Object not implemented`, )
  //   // return null
  // }
}








