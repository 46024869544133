
<!-- 
  [src]="lazySrc" -->
  <!-- ? src is there just to have aspect ratio -->
  <!-- assets/img/product-img.png -->

<!-- <div class="wrapper"> -->
  <!-- *ngIf="src" -->
  <img  #imgElem
    [class]="cssClasses"
    src="{{ initialSrc }}"
    (load)="imgFinishedLoading($event)"
    (error)="imgFinishedLoadingwithError($event)"
  >
<!-- </div> -->
