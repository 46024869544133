import { Cart } from "./cart"

export class User {
  // [key: string]: any
  
  _id!: string
  currency?: string // Currency.code
  language?: string
  lastCart?: Cart/* Lastcart */
  timestamp_last_update?: number
  nation?: string // Nation.code
  
  constructor(data: any) {
    this.create(data)
  }
  
  // * method
  
  create(data: any) {
    if (data) {
      this._id = data._id
      this.currency = data.currency
      this.language = data.language
      this.lastCart = data.lastCart
      this.timestamp_last_update = data.timestamp_last_update
      this.nation = data.nation
      
      
    }
  }
  
  /**
   * assigns only the fields present in data
   * missing checks:
   * data may have fields which are not present in 'this'
   * data may be an entirely different class
   * 
   */
  update(data: any) {
    if (data) {
      Object.keys(data).forEach((key) => {
        (<any>this)[key] = data[key]
      })
    }
  }
  
}











