import { DataService } from "../services/data.service"


export class Product {

  _id!: string
  brand!: string // Brand.brand
  countries: any = {}
  countries_group: any = {}
  currency?: string // Currency.code
  currency_converted!: string // Currency.code
  idBrand!: string
  name?: string
  nation: string = ""; // Nation.code
  price?: number
  original_price_converted?: number;
  price_converted_cart?: number
  price_converted_with_fee?: number
  price_converted_with_fee_cart?: number
  providerType?: 0 | 1
  quantity?: number
  quantity_cart?: number
  sku?: string
  have_discount?: boolean;

  constructor(data: any) {
    this.create(data)
  }

  // * method

  create(data: any) {
    if (data) {
      this._id = data._id
      this.brand = data.brand
      this.countries = data.countries
      this.countries_group = data.countries_group
      this.currency = data.currency
      this.currency_converted = data.currency_converted
      this.idBrand = data.idBrand
      this.name = data.name
      this.nation = data.nation
      this.price = data.price
      this.price_converted_cart = data.price_converted_cart
      this.price_converted_with_fee = data.price_converted_with_fee
      this.price_converted_with_fee_cart = data.price_converted_with_fee_cart
      this.providerType = data.providerType
      this.quantity = data.quantity
      this.quantity_cart = data.quantity_cart
      this.sku = data.sku;
      this.original_price_converted = data.original_price_converted;
      this.have_discount = data.have_discount;
    }
  }

  /**
   * assigns only the fields present in data
   * missing checks:
   * data may have fields which are not present in 'this'
   * data may be an entirely different class
   *
   */
  update(data: any) {
    if (data) {
      Object.keys(data).forEach((key) => {
        (<any>this)[key] = data[key]
      })

      DataService.globalSettings.next({
        action: "cart_products-changed",
      })
    }
  }

  /**
   * assigns new values only if they are not nullish (null or undefined)
   * can be bad if some fields are nullish and we want those nullish values
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
   */
  /* update(data: any) {
    if (data) {
      this._id = data._id ?? this._id
      this.brand = data.brand ?? this.brand
      this.countries = data.countries ?? this.countries
      this.countries_group = data.countries_group ?? this.countries_group
      this.currency = data.currency ?? this.currency
      this.currency_converted = data.currency_converted ?? this.currency_converted
      this.idBrand = data.idBrand ?? this.idBrand
      this.name = data.name ?? this.name
      this.price = data.price ?? this.price
      this.price_converted = data.price_converted ?? this.price_converted
      this.price_converted_cart = data.price_converted_cart ?? this.price_converted_cart
      this.price_converted_with_fee = data.price_converted_with_fee ?? this.price_converted_with_fee
      this.price_converted_with_fee_cart = data.price_converted_with_fee_cart ?? this.price_converted_with_fee_cart
      this.quantity = data.quantity ?? this.quantity
      this.quantity_cart = data.quantity_cart ?? this.quantity_cart
      this.sku = data.sku ?? this.sku


    }
  } */




}


// used at the beginning to roll out the bulk of the front-end
export type ProductOLD = {
  id: string
  img?: string
  imgAlt?: string // <img alt="">
  name: string

  // * useful for detail page

  desc?: string
  // item list
  variations?: any[]
  // tabs?: any[]

  [key: string]: any
}



