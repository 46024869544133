import { getListItem } from "../misc/util";
import { DataService } from "../services/data.service";
import { ViewService, ViewService as View } from "../services/view.service";
import { Product } from "./product";

type productsElemRemove_opts = {
  el__id: string
  elIndex?: number
}
type productsSet_opts = {
  flux: "create" | "update"
  value: Product[]
}

export class Cart {

  arrayProducts: Product[] = []
  cart_number!: string // looks like an id
  timestamp_create?: number
  timestamp_update?: number
  timestamp_validity?: number
  total?: number
  total_currency!: string // currency.code
  total_with_fee?: number
  total_converted_currency!: string // currency.code
  total_with_fee_converted?: number // currency.code

  $productsMap: Map<string, Product> = new Map()


  constructor(data: any) {
    this.create(data)
  }


  // * method

  // * method/constructor-like

  create(data: any) {
    if (data) {
      this.timestamp_create = data.timestamp_create
      this.timestamp_update = data.timestamp_update
      this.timestamp_validity = data.timestamp_validity
      this.cart_number = data.cart_number
      /* this.arrayProducts =  */this.productsSet({
        flux: "create",
        value: data.arrayProducts,
        // value: <Product[]>removalTest,
      })
      this.total_currency = data.total_currency
      this.total = data.total
      this.total_with_fee = data.total_with_fee
      this.total_with_fee_converted = data.total_with_fee_converted;
      this.total_converted_currency = data.total_converted_currency;

    }
  }

  /**
   * assigns only the fields present in data
   * missing checks:
   * data may have fields which are not present in 'this'
   * data may be an entirely different class
   *
   */
  update(data: any) {
    if (data) {
      Object.keys(data).forEach((key) => {
        switch (key) {
          case "arrayProducts":
            /* (<any>this)[key] =  */this.productsSet({
              flux: "update",
              value: data[key],
            })
            break;

          default:
            (<any>this)[key] = data[key]
        }

      })
    }
  }

  // * method/field

  productsSet(opts: productsSet_opts) {
    const {
    // let {
      flux,
      value,
    } = opts/*  || {} */
    console.log(`productsSet() opts`, opts)

    if (!value) {
      this.arrayProducts = []
      View.updateView.next(null)
      return
    }

    switch (flux) {
      case "create":
        this.arrayProducts = []
        value.forEach((el: Product) => {
          this.productsElemAdd(el)
        })
        break;

      case "update":
        // * added/updated values

        // for tests on elems removal
        // value = []

        value.forEach((el: Product) => {
          const found: Product = getListItem({
            // key: "_id",
            key: el._id,
            // list: this.arrayProducts,
            list: this.$productsMap,
            // value: el._id,
          })

          if (found) {
            found.update(el)
          } else {
            this.productsElemAdd(el)
          }
        })

        // * removed values

        const valuesWereRemoved = value.length < this.arrayProducts.length
        if (!valuesWereRemoved) {
          // console.log(`return: !valuesWereRemoved`, )
          break
        }

        let elRemoved: boolean | null

        // console.log(`this.arrayProducts`, this.arrayProducts)
        // console.log(`value`, value)

        // ...in this test I am doing...
        // without .slice() only reads 3 out of 5 elems o.o
        // .slice() gives me a clone of the array

        // ! BUG seems related to arrayProducts
        // ! and to the index(i) of the array
        /*
        working setups:

        • iterating over
          this.arrayProducts.slice() instead of this.arrayProducts
          (basically cloning the array)
        • removing elems w/
          productsElemRemove() without passing elIndex
          ---

        • iterating over
          this.$productsMap
        • removing elems w/
          productsElemRemove() without passing elIndex
          ---

        • using the code of "FIX #work around 1"
        */

        // let i = 0 // bugged anyway

        // this.arrayProducts.slice().forEach((el/* , i */) => {
        this.$productsMap.forEach((el) => {
          // console.log(el)
          elRemoved = null
          elRemoved = !( value.some((valueEl: Product) => valueEl._id === el._id) )

          if (elRemoved === true) {
            // ? tested this method outside of here (directly from html)
            // ? and seems to work fine really
            // ? even when passing 'elIndex'
            this.productsElemRemove({
              el__id: el._id,
              // elIndex: i,
            })

            // FIX #work around 1
            // this.$productsMap.delete(el._id)
          }

          // i++
        })

        // FIX #work around 1
        // this.arrayProducts = []
        // this.$productsMap.forEach((el) => {
        //   this.arrayProducts.push(el)
        // })

        break;

      default:
        break;
    }

    View.updateView.next(null)
  }

  productsElemAdd(el: Product) {
    this.$productsMap.set(el._id, new Product(el))
    this.arrayProducts.push(
      this.$productsMap.get(el._id)!
    )
    // this.arrayProducts.push(new Product(el)) // store a copy of el

    DataService.globalSettings.next({
      action: "cart_products-changed",
    })
    View.updateView.next(null)
  }

  productsElemRemove(opts: productsElemRemove_opts) {
    const {
      el__id,
    } = opts/*  || {} */
    let {
      elIndex,
    } = opts/*  || {} */

    if (!elIndex && elIndex !== 0) {
      elIndex = this.arrayProducts.findIndex(
        (el) => el._id === el__id
      )
    }
    if (elIndex !== -1) {
      this.arrayProducts.splice(elIndex!, 1)
    }

    // * subfields
    this.$productsMap.delete(el__id) // ? move to start of method?

    DataService.globalSettings.next({
      action: "cart_products-changed",
    })
    View.updateView.next(null)
  }

  getProduct(id: string): Product | undefined{
    return this.arrayProducts.find((elem: Product) => elem._id === id);
  }




}





const removalTest/* : Product[] */ = [
  {
    "_id": "6148b079eeeb0027d784027c",
    "sku": "ArcheAge-3250-credits",
    "name": "ArcheAge 3250 credits",
    "brand": "ArcheAge",
    "countries": {},
    "countries_group": {
      "ww": "ww"
    },
    "providerType": 1,
    "idBrand": "6144ad7f9b4f27dcf3e4506f",
    "price": 20,
    "currency": "USD",
    "nation": "ae",
    "currency_converted": "AUD",
    "price_converted": 26.69,
    "price_converted_with_fee": 27.22,
    "quantity_cart": 1,
    "price_converted_cart": 26.69,
    "price_converted_with_fee_cart": 27.22
  },
  {
    "_id": "615ef026c976a81f3d148b94",
    "sku": "xxxx",
    "name": "test 1€",
    "brand": "Ducati",
    "countries": {},
    "countries_group": {
      "ww": "ww"
    },
    "providerType": 0,
    "idBrand": "615eb4b0c976a81f3d0fc35d",
    "price": 1,
    "currency": "EUR",
    "nation": "ae",
    "currency_converted": "AUD",
    "price_converted": 1.55,
    "price_converted_with_fee": 1.58,
    "quantity_cart": 1,
    "price_converted_cart": 1.55,
    "price_converted_with_fee_cart": 1.58
  },
  {
    "_id": "6148b075eeeb0027d78401a5",
    "sku": "Netflix-100-AED",
    "name": "Netflix-100-AED",
    "brand": "Netflix",
    "countries": {
      "ae": "ae"
    },
    "countries_group": {},
    "providerType": 1,
    "idBrand": "6144ad7f9b4f27dcf3e44f57",
    "price": 100,
    "currency": "AED",
    "nation": "ae",
    "currency_converted": "AUD",
    "price_converted": 36.34,
    "price_converted_with_fee": 37.07,
    "quantity_cart": 1,
    "price_converted_cart": 36.34,
    "price_converted_with_fee_cart": 37.07
  },
  {
    "_id": "6148b06beeeb0027d783ffbc",
    "sku": "CherryCredits-10.000-CC",
    "name": "Cherry Credits 10.000 CC",
    "brand": "Cherry Credits",
    "countries": {},
    "countries_group": {
      "ww": "ww"
    },
    "providerType": 1,
    "idBrand": "6144ad819b4f27dcf3e454f9",
    "price": 10,
    "currency": "SGD",
    "nation": "ae",
    "currency_converted": "AUD",
    "price_converted": 9.91,
    "price_converted_with_fee": 10.11,
    "quantity_cart": 1,
    "price_converted_cart": 9.91,
    "price_converted_with_fee_cart": 10.11
  },
  {
    "_id": "6148b074eeeb0027d784018f",
    "sku": "Amazon-50-AED",
    "name": "Amazon 50 AED",
    "brand": "Amazon",
    "countries": {
      "ae": "ae"
    },
    "countries_group": {},
    "providerType": 1,
    "idBrand": "6144ad7e9b4f27dcf3e44eb0",
    "price": 50,
    "currency": "AED",
    "nation": "ae",
    "currency_converted": "AUD",
    "price_converted": 18.17,
    "price_converted_with_fee": 18.53,
    "quantity_cart": 1,
    "price_converted_cart": 18.17,
    "price_converted_with_fee_cart": 18.53
  }
]























